import React from 'react'

function Markee() {
  
  return (
    <>
    <section id="notice ">
        <div className="notice font-change shadow-lg ">
          <div className="h-8 w-auto relative bg-blue-200 flex justify-center  m-auto shadow-inner -z-10">
              <div className="h-8 w-[30%] sm:w-[31%] left-0  absolute flex justify-end items-center bg-[#00072e] shadow-inner z-10">
                  <span className="text-white  sm:mr-[2rem]  font-bold text-xs lg:text-[1rem] ml-1 sm:ml-0">Special Annnouncements</span>
                  <svg  xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" fill="currentColor"  viewBox="0 0 16 16" style={{ right: `calc(22% - 61px)`, top: '9.5px', color: '#00072e', margin: '-3px -0.7rem' }}>
                    <path  d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                </svg>
              </div>

              <marquee className=" overflow-hidden sm:w-[63%] w-[65%] ml-[7rem] lg:ml-[25rem] pt-[8px] bg-[hsl(21, 69%, 14%)]"  behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" >						
                          <a href="#">
                              <span className='text-black'>Public Declaration, 11 Jan 2024. <span className='text-black'>&nbsp; | &nbsp;</span> </span>
                          </a>
                          <a href="#"> 
                              <span className='text-black'>UG Programme : Weightage for Initial Selection Process <span className='text-black'>&nbsp; | &nbsp;</span> </span>
                          </a>
                          <a href="https://docs.google.com/forms/d/e/1FAIpQLSe1S-fvJQO0qZ7TaoQpj_UK41qsj8-5lE7CfIgpzK1OBumorQ/viewform" target="_blank">
                              <span className='text-black'> UG Admissions 2024 <span className='text-black'>&nbsp; | &nbsp;</span> </span>
                          </a>
              </marquee>
              <div className="h-8 w-[10%] right-0 bg-blue-200 absolute shadow-inner hidden lg:block"></div>
          </div>
        </div>
    </section>
    </>
  )
}

export default Markee