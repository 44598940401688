// Dashboard.js
import React from "react";
import { useAuth } from "./AuthProvider";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const { profile, logOut } = useAuth();

  return (
    <div className="h-[100vh]">
    <div className="h-14 flex justify-between items-center font-change bg-[#c5cbe7] ">
      <h1 className="text-2xl font-semibold text-[#151d3f] px-3">ADMIN PANEL</h1>
      {profile && (
        <div className="flex flex-row justify-center items-center space-x-2 h-14 mx-2 ">
          <img
            src={profile.picture}
            alt={profile.name}
            className="h-10 w-10 rounded-full"
          />
          {/* <span className="font-bold">{profile.name}</span> */}
          <span className="hidden md:block">{profile.email}</span>
          <button
            onClick={logOut}
            className="bg-black my-2 px-6 py-1 text-white rounded-3xl mx-4 hover:bg-gray-800 hover:scale-105 duration-300"
          >
            Logout
          </button>
        </div>
      )}
    </div>

   
    <div className='mb-14 mx-auto flex justify-center text-sm md:text-base '>
    <Link to='/' className='md:px-8 py-3 px-4 bg-blue-600 text-white rounded-l-lg '>IMAGE's</Link>
    <Link to='/Pdf' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white '>PDF's</Link>
    <Link to='/' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white rounded-r-lg'>News</Link>
        </div>
    </div>
  );
};

export default Dashboard;
