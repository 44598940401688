// AuthProvider.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { GoogleLogin, googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const authorizedEmails = ["pranayndv@gmail.com", "omkarpawar7000@gmail.com"];

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          const userProfile = res.data;
          setProfile(userProfile);
          if (authorizedEmails.includes(userProfile.email)) {
            setIsAuthorized(true);
          } else {
            setIsAuthorized(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const logOut = () => {
    googleLogout();
    setProfile(null);
    setIsAuthorized(false);
  };

  return (
    <AuthContext.Provider value={{ profile, isAuthorized, login, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
