import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const images = [
  '/assets/carousel/item1.png',
  '/assets/carousel/item3.png',
];

function Slider() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  return (
    <div className="relative w-full h-48 md:h-[27rem] xl:h-[100vh]">
      <AnimatePresence initial={false} custom={currentImageIndex}>
        <motion.img
          key={currentImageIndex}
          src={images[currentImageIndex]}
          alt={`slide ${currentImageIndex}`}
          className="object-fit-contain w-full h-full absolute top-0 left-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 2}}
        />
      </AnimatePresence>
      <button
        onClick={prevImage}
        className="absolute top-1/2 left-4 md:left-8 xl:left-12 transform -translate-y-1/2 rounded-full px-3 py-3 bg-white/15 bg-opacity-2  hover:scale-110 duration-300 bg-blur-2xl"
      >
        <img  src="/assets/svg/left.svg" className='w-10 h-10 ' alt="" />
        </button>
      <button
        onClick={nextImage}
        className="absolute top-1/2 right-4 md:right-8 xl:right-12 transform -translate-y-1/2 px-3 py-3 rounded-full bg-white/15 bg-opacity-2  hover:scale-110 duration-300 bg-blur-2xl"
      >
              <img  src="/assets/svg/right.svg" className='w-10 h-10 ' alt="" />
      </button>
    </div>
  );
}

export default Slider;
