import React from 'react'
import { Table } from "flowbite-react";
import { motion } from 'framer-motion';
function Co() {
  return (
    <motion.section className="md:px-32 px-3 py-6"
    
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}
    >
    <h1 className='text-xl underline underline-offset-8 font-bold  my-3 text-center'>FIRST YEAR DEGREE</h1>
    <div className="overflow-x-auto my-7 border border-gray-200 rounded-xl">
    <Table>
      <Table.Head >
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Sr.No.</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">COURSES</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">INTAKE</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y font-bold">
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>1</Table.Cell>
          <Table.Cell>Civil Engineering</Table.Cell>
          <Table.Cell>90</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>2</Table.Cell>
          <Table.Cell>Mechanical Engineering</Table.Cell>
          <Table.Cell>90</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>3</Table.Cell>
          <Table.Cell>Electronics & Telecommunication Engineering</Table.Cell>
          <Table.Cell>45</Table.Cell>
        </Table.Row>        
        
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>4</Table.Cell>
          <Table.Cell>Computer Engineering</Table.Cell>
          <Table.Cell>45</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </div>

  <h1 className='text-xl underline underline-offset-8 font-bold  my-3 text-center'>FIRST YEAR DIPLOMA ENGINEERING</h1>
  <div className="overflow-x-auto mb-14 mt-8 border border-gray-200 rounded-xl">
    <Table>
      <Table.Head >
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Sr.No.</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">COURSES</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">INTAKE</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y font-bold">
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>1</Table.Cell>
          <Table.Cell>Civil Engineering</Table.Cell>
          <Table.Cell>45</Table.Cell>
        </Table.Row>        
    
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>2</Table.Cell>
          <Table.Cell>Mechanical Engineering</Table.Cell>
          <Table.Cell>45</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </div>

  <h1 className='text-xl underline underline-offset-8 font-bold  my-3 text-center'>FIRST YEAR B.SC</h1>
  <div className="overflow-x-auto mb-14 mt-8 border border-gray-200 rounded-xl">
    <Table>
      <Table.Head >
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Sr.No.</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">COURSES</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">INTAKE</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y font-bold">
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>1</Table.Cell>
          <Table.Cell>B.Sc (Computer Science)</Table.Cell>
          <Table.Cell>60</Table.Cell>
        </Table.Row>        
    
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>2</Table.Cell>
          <Table.Cell>B.Sc (Information Technology)</Table.Cell>
          <Table.Cell>60</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </div>
  </motion.section>
  )
}

export default Co