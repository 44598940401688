import React from "react";
import "../styles/markee.css"
import { Carousel } from "flowbite-react";
import { motion } from "framer-motion";

function News() {
  return (
    <section className="flex flex-col lg:flex-row items-center justify-center lg:space-x-[8rem]">
      <motion.div
        className="leftOut h-[19rem] sm:h-[23rem] w-[82%] md:h-[26rem] xl:h-[30rem] lg:w-[50%] xl:w-[40%] bg-blue-100 p-6 mx-6 lg:mt-2 mt-14 rounded-lg text-center shadow-xl font-change"
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ ease: "easeIn", duration: 2 }}
      >
        <h3 className="text-[1.5rem] font-semibold flex space-x-2">
          <span>UPDATES </span>
          <img
            src="assets/svg/clock.png"
            className="mx-2 w-6 h-6 mt-2"
            alt=""
          />
          <button
            type="button"
            className="w-[5rem] h-[1.8rem] mt-[0.4rem] bg-blue-900 text-xs text-white px-2 py-1 rounded-lg hover:bg-blue-800 hover:scale-110 duration-300"
          >
            View all
          </button>
        </h3>

        <div className="h-56 sm:h-64 md:h-80 2xl:h-96 md:mt-3">
          <Carousel slideInterval={5000}>
            <img src="/assets/update/item1.jpg" alt="..." className="md:h-[20rem] lg:h-[22rem]" />
            <img src="/assets/update/item2.jpg" alt="..." className="md:h-[20rem] lg:h-[22rem]" />
            <img src="/assets/update/item3.jpg" alt="..." className="md:h-[20rem] lg:h-[22rem]" />
          </Carousel>
        </div>
      </motion.div>
      <motion.div
        className="rightOut h-[22rem] lg:h-[30rem] w-[82%] lg:w-[30%] bg-blue-100 p-6 m-6 rounded-lg text-center shadow-xl relative font-change"
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ ease: "easeIn", duration: 2 }}
      >
        <h3 className="text-[1.5rem] font-semibold flex space-x-2">
          <span>NEWS </span>
          <img
            src="assets/svg/newspaper.png"
            className="mx-2 w-6 h-6 mt-2"
            alt=""
          />
          <button
            type="button"
            className="w-[5rem] h-[1.8rem] mt-[0.4rem] bg-blue-900 text-xs text-white px-2 py-1 rounded-lg hover:bg-blue-800 hover:scale-110 duration-300"
          >
            View all
          </button>
        </h3>
        <div className="overflow-hidden h-[17rem] lg:h-[25rem] mt-4 relative">
          <div className="overflow-hidden h-[25rem] mt-4 relative">
            <div className="marquee-container">
              <div className="marquee">
                <div className="item flex flex-col w-full text-red-600 font-semibold text-start">
                  {/* Your news items */}
                  <div class="m-3 flex  ">
                <a href="">
                  Admissions open for 2023-24 First year and Direct second year
                  Degree courses in Civil, Mechanical, Computer, Electronics and
                  Electronics & Telecommunication Engineering.
                </a>
                <img src="/assets/svg/new.gif" className="h-8" alt="" />
              </div>

              <div class="m-3 flex  ">
                <a href="">
                  Admissions open for 2023-24 First year and Direct second year
                  Diploma in Civil and Mechanical Engineering.
                </a>
                <img src="/assets/svg/new.gif" className="h-8" alt="" />
              </div>

              <div class="m-3 flex ">
                <a href="">
                  Admissions open for 2023-24 First year B.Sc. in Computer
                  Science and Information Technology.
                </a>
              </div>
              
              <div class="m-3 flex ">
                <a href="">
                  Priyanka Parab & Karan Kadane from BE computer Has selected in
                  Alphonsol software Company, Thane as a Software developer
                  Trainee. Campus Interview had conducted on 12th April in
                  college premises.
                </a>
              </div>

              <div class="m-3 flex ">
                <a href="https://www.facebook.com/mitmoras/posts/pfbid02r4hyrgExLDhJCimvE3xpdUPBZjJkZ65jiVYAEavjwZW6GK78zwsiZ8x4i8FKwNkBl">
                  We are thrilled to announce that our esteemed alumni,
                  Mr.Abhishek Singh  , has been bestowed with
                  an Honorary Ph.D. in Cybersecurity by the prestigious Oxford
                  University.
                </a>
              </div>

              <div class="m-3 flex ">
                <a href="https://youtu.be/GU5o69UrO44">
                एम. आय. टी. एम. कॉलेज ऑफ इंजिनियरिंगचा वार्षिक पारितोषिक वितरण समारंभ उत्साहात | MITM
                </a>
              </div>

              <div class="m-3 flex ">
                <a href="https://youtu.be/J0uxoxtd5u4">
                तरुणाईला पाहिजे रोजगार, तरुणाईच्या मनात काय? | MITM
                </a>
              </div>
              
              <div class="m-3 flex ">
                <a href="https://kokanshahi.com/?p=8675">
                "एमआयटीएम" कॉलेजमध्ये आयोजित विज्ञान प्रदर्शनात शिवाजी इंग्लिश स्कूल प्रथम

                </a>
              </div>
              
              <div class="m-3 flex ">
                <a href="https://youtu.be/y2duz5hNCds">
                जयवंती बाबू फाउंडेशनचे एम. आय. टी. एम. कॉलेजमध्ये REX 2K24 उत्साहात
                </a>
              </div>

              <div class="m-3 flex ">
                <a href="https://epaper.lokmat.com/articlepage.php?articleid=LOK_HSDH_20240401_3_14">
                सुकळवाड अभियांत्रिकी महाविद्यालयाचा पदवीदान सोहळा
                </a>
              </div>

                  {/* Add more news items as needed */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
}

export default News;



